.NavButton {
    font-family: "GT America Mono Regular";
    text-align: center;
    border: none;
    background-color: #3a3a40;
    color: #F3EFE0;
    font-size: 17px;
    padding-left: 1%;
    padding-right: 1%;
    min-width: 8%;
    height: 100%;
    cursor: pointer;
}
.NavButton:hover {
    background-color: #2B2B2B;
    cursor: pointer;
}
.NavButtonActive {
    font-family: "GT America Mono Regular";
    text-align: center;
    border: none;
    background-color: gray;
    color: #F3EFE0;
    font-size: 17px;
    padding-left: 1%;
    padding-right: 1%;
    min-width: 8%;
    height: 100%;
    cursor: pointer;
}
.NavButtonActive:hover {
    background-color: #2B2B2B;
    cursor: pointer;
}
@media (max-width: 768px) {
    .NavButton {
        width: 100%;
        text-align: right;
        height: 10vh;
    }
    .NavButtonActive {
        width: 100%;
        text-align: right;
        height: 10vh;
    }
}
.Tab:nth-child(1) {
    color: #F3EFE0;
    background: none;
    font-size: 17px;
    border: none;
    padding-left: 1%;
    padding-right: 1%;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border: 1px solid #F3EFE0;
    border-right: none;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
}

.Tab:nth-child(2) {
    color: #F3EFE0;
    background: none;
    font-size: 17px;
    border: none;
    padding-left: 1%;
    padding-right: 1%;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border: 1px solid #F3EFE0;
    border-left: none;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
}

.TabActive:nth-child(1) {
    background: #F3EFE0;
    color: #1B1B1E;
    font-size: 17px;
    border: none;
    padding-left: 1%;
    padding-right: 1%;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-left: 1px solid #F3EFE0;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
}
.TabActive:nth-child(2) {
    background: #F3EFE0;
    color: #1B1B1E;
    font-size: 17px;
    border: none;
    padding-left: 1%;
    padding-right: 1%;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-left: 1px solid #F3EFE0;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
}
.Filter {
    color: #F3EFE0;
    border: 1px solid #3A3A40;
    background-color: #3A3A40;
    text-align: center;
    border-radius: 2rem;
    font-size: 17px;
    padding: 0.5%;
    min-width: 8%;
    height: 100%;
    cursor: pointer;
    font-family: "Nunito";
}
.FilterActive {
    background-color: #F3EFE0;
    border: 1px solid #F3EFE0;
    color: #1B1B1E;
    text-align: center;
    border-radius: 2rem;
    font-size: 17px;
    padding: 0.5%;
    min-width: 8%;
    height: 100%;
    cursor: pointer;
    font-family: "Nunito";
}
@media (max-width: 768px) {
    .Filter {
        width: 30vw;
        margin-bottom: 1vh;
    }
    .FilterActive {
        width: 30vw;
        margin-bottom: 1vh;
    }
}
.About {
    font-family: "Nunito";
    background-color: #1B1B1E;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #CE8147;
    border-radius: 2rem;
    color: #CE8147;
    font-size: 17px;
    padding-left: 1%;
    padding-right: 1%;
    min-width: 20%;
    cursor: pointer;
    padding: 1%;
    margin: 1%;
}
@media (max-width: 768px) {
    .About {
        width: 30vw;
    }
}