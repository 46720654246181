.ContactPage {
    height: 95vh;
    width: 100vw;
    overflow-x: hidden;
    background-color: black;
    color: #F3EFE0;
    display: flex;
    flex-direction: column;
}
.ContactWrapper {
    background-image: URL("../images/contact2.JPG");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100vw;
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 5vh;
}
.ContactImage {
    width: 100vw;
    height: 40vh;
    z-index: 1;
}
.Title {
    z-index: 100;
    
}
.Contact {
    display: flex;
    flex-direction: row;
    size: 300;
    align-items: top;
    justify-content: center;
}
.IconWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1%;
    color: #F3EFE0;
    text-decoration: none;
    font-family: "Nunito";
    margin-top: 10vh;
    font-size: 2em;
    padding: 1%;
    border: 1px solid #F3EFE0;
    border-radius: 1rem;
    min-width: 15vw;
}
.IconWrapper:first-child {
    margin-right: 5vw;
}
.IconWrapper:last-child {
    margin-left: 5vw;
}
.Icon {
    margin-left: 1vw;
    color: #F3EFE0;
    margin-bottom: 0.5vh;
}
