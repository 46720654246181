/*
    #171A21
    #D1CAA1
    #A44200
    #087E8B
    #F5E5fC
    #EBEBEB

*/
@font-face {
    font-family: "GT America Mono Regular";
    src: url("./assets/fonts/GT America Mono Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Nunito";
    src: url("./assets/fonts/Nunito-VariableFont_wght.ttf") format("truetype");
}
@font-face {
    font-family: "United Sans";
    src: url("./assets/fonts/UnitedSansCdMd.otf") format("opentype");
}
@font-face {
    font-family: "Source Serif";
    src: url("./assets/fonts/SourceSerif4-VariableFont_opsz,wght.ttf") format("truetype");
}
@font-face {
    font-family: "Oxanium";
    src: url("./assets/fonts/Oxanium-VariableFont_wght.ttf") format("truetype");
}
@font-face {
    font-family: "Coda";
    src: url("./assets/fonts/Coda-Regular.ttf") format("truetype");
}
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    max-width:100%;
   }
   
.container {
    z-index: 1000;
}