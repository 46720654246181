.ProjectsPage {
    min-height: 100vh;
    padding-top: 4vh;
    width: 100vw;
    overflow-x: hidden;
    background-color: #1B1B1E;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ProjectsIntro {
    color: #F3EFE0;
    padding-top: 1vh;
    text-align: center;
    word-wrap: break-word;
    font-family: "Nunito";
    margin-bottom: 2vh;
    width: 75vw;
}
.ProjectsFilter {
    color: #F3EFE0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    font-family: "Nunito";
}
.FilterRow {
    margin-left: 2vw;
    align-items: center;
}
.FilterRow > *:not(:last-child) {
    margin-right: 1vw;
}
.FilterTitle {
    text-align: left;
    min-width: 10vw;
    margin-bottom: 1vh;
    margin-top: 1vh;
}
.ProjectsGrid {
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 5vh;
}
@media (max-width: 768px) {
    .ProjectsIntro {
        text-align: justify;
    }
    .FilterRow {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
}