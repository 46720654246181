.ProjectCard {
    height: auto;
    width: 40vw;
    background: linear-gradient(#CE8147, #F3EFE0);
    border-radius: 1em;
    padding: 1em;
    margin: 1em;
    word-wrap: break-word;
    font-weight: bold;
}
.PCTitle {
    font-size: 1.5em;
    border-bottom: 1px solid #1B1B1E;
    padding-bottom: 1vh;
    margin-bottom: 1vh;
    font-family: "GT America Mono Regular";
}
.PCDescription {
    color: #1B1B1E;
    margin-bottom: 1vh;
    font-family: "Nunito";
    min-height: 25vh;
    text-align: justify;
}
.PCKeywords {
    color: #1B1B1E;
    margin-bottom: 1vh;
    font-family: "Nunito";
    min-height: 6vh;
}
@media (max-width: 768px) {
    .ProjectCard {
        width: 80vw;
    }
    .PCDescription {
        min-height: 10vh;
    }
}