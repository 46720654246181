.Navbar {
    position: sticky;
    top: 0;
    background-color: #3a3a40;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    padding-left: 10px;
    height: 6vh;
    z-index: 9000;
}
.Logo {
    height: 6vh;
    width: auto;
    margin-left: 1%;
}

.NavbarTitle { 
    color: #F3FEF0;
    vertical-align: center;
    cursor: pointer;
}
.NavbarButtons {
    padding-right: 1vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    
    flex-grow: 1;
    height: 100%;
}
.Dropdown {
    color: #F3EFE0;
    margin-right: 1%;
    height: 6vh;
    font-size: 30px;
}
@media (max-width: 768px) {
    .NavbarButtons {
        position: absolute;
        top: 6vh;
        right: 0vw;
        width: 35vw;
        height: 40vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
    }
}