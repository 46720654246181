.HomePage {
    background-image: url("../images/home-sunset.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 94vh;
}
.Home {
    padding-top: 10vh;
    color: #F3EFE0;
    opacity: 0;
    animation: fadeIn 2s ease-in-out forwards;
    z-index: 10;
}
@keyframes fadeIn {
    from {
        opacity: 0; 
      }
      to {
        opacity: 1; 
      }
}
.Title {
    vertical-align: center;
    text-align: center;
    font-family: "Coda";
    font-size: 2.5em;
    
}
.fade-text {
    padding-top: 5vh;
    text-align: center;
    opacity: 1;
    animation: fade 3s infinite;
    font-family: "Oxanium";
    font-size: 2em;
  }
  @keyframes fade {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  
  @media (max-width: 768px) {
    .Title {
        font-size: 1.5em;
    }
    .fade-text {
        font-size: 1em;
    }
  }