.PhotographyPage {
    min-height: 100vh;
    color: white;
    background-color: black;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 8vh;
}
.PhotographyIntro {
    padding-top: 4vh;
    width: 75vw;
    text-align: center;
    word-wrap: break-word;
    font-family: "Nunito";
    margin-bottom: 3vh;
}
.PhotographyTabs {
    height: 7vh;
    width: 60%;
    margin-bottom: 3vh;
    display: flex;
    align-items: center;
    justify-content: row;
}

.PhotographyGrid {
    width: 100%;
}