.AboutPage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.Intro {
    min-height: 94vh;
    background-color: #1B1B1E;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    flex-direction: column;
}
.IntroWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}
.IntroImage {
    width: 30vw;
    height: auto;
    margin: 2vw;
}
.IntroText {
    max-width: 50vw;
    text-align: justify;
    color: #F3EFE0;
    word-wrap: break-word;
    line-height: 1.5;
    font-family: "Nunito";
}
.MenuBar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3vh;
}
.AboutFooter {
    color: #F3EFE0;
    width: 100%;
    margin: auto;
    font-size: 2em;
    text-align: center;
    cursor: pointer;
}
.Education {
    min-height: 100vh;
    padding-top: 7vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
}
@media (max-width: 768px) {
    .Intro {
        display: flex;
        flex-direction: column;
        margin-bottom: auto;
    }
    .IntroWrapper {
        flex-direction: column;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
    .Intro .IntroImage {
        width: 80vw;
        height: auto;
        margin: 5%;
        margin-bottom: 3vh;
    }
    .Intro .IntroText {
        min-width: 90vw;
    }
    .Intro .MenuBar {
        flex-direction: column;
        align-items: center;
        margin-bottom: 3vh;
      }
  }
.EducationTitle {
    font-family: "GT America Mono Regular";
    font-weight: bold;
    font-size: 2.5em;
    color: #F3EFE0;
    text-align: center;
    margin-bottom: 2vh;
    word-wrap: break-word;
} 
.EducationWrapper {
    color: black;
    padding-top: 1vh;
    padding-bottom: 1vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #CEB888;
    width: 60vw;
    border: 1px solid #CEB888;
    border-radius: 2em;
}
.EducationImage {
    width: 50vw;
    height: auto;
    margin-bottom: 2vh;
    border-radius: 2em;
}
.PurdueTitle {
    font-family: "United Sans";
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 1vh;
    padding-top: 1vh;
}
.PurdueDescription {
    font-family: "Source Serif";
    margin-bottom: 1vh;
    width: 100%;
    text-align: center;
}
.EducationFooter {
    color: #F3EFE0;
    width: 100%;
    margin: auto;
    font-size: 2em;
    text-align: center;
    cursor: pointer;
}
.Skills {
    height: auto;
    padding-top: 7vh;
    padding-bottom: 3vh;
    background-color: #CE8147;
    color: #F3EFE0;
    text-align: center;
}
.SkillsTitle {
    font-family: "GT America Mono Regular";
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 1vh;
}
.SkillsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    min-width: 70vw;
    min-height: 80vh;
    text-align: left;
}
.SkillsColumn {
    padding: 3%;
    min-width: 35vw;
}
.SkillsCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1vh;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.SkillsCardTitle {
    font-family: "Nunito";
    margin-right: 1vw;
}
.SkillsCard:hover {
    transform: scale(1.05);
    font-weight: bold;
    cursor: default;
}
.SkillsFooter {
    color: #F3EFE0;
    width: 100%;
    font-size: 2em;
    text-align: center;
    cursor: pointer;
}
@media (max-width: 768px) {
    .SkillsWrapper {
        flex-direction: column;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
    .SkillsColumn {
        min-width: 90vw;
    }
}
.Experience {
    min-height: 100vh;
    padding-top: 7vh;
    padding-bottom: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F3EFE0;
    color: #1B1B1E;
}
.ExperienceTitle {
    font-family: "GT America Mono Regular";
    font-weight: bold;
    margin-bottom: 4vh;
    font-size: 2.5em;
    color: #1B1B1E

}
.ExperienceWrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    min-width: 50vw;
    justify-content: center;
    align-items: center;
}
.ExperienceBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}
.ExperienceCard {
    font-family: "GT America Mono Regular";
    padding: 1%;
    text-align: center;
    line-height: 1.5;
    max-width: 75vw;
}
.ExperienceCard:first-child {
    list-style-type: none;
}
.ExperienceCardTitle {
    
    font-family: "Nunito";
    font-weight: bold;
    text-decoration: underline;
}
.ExperienceCardText {
    font-family: "Nunito";
}
.Divider {
    margin-bottom: 3vh;
    border-top: 2px solid #3A3A40;
    opacity: 0.7;
    width: 15vw;
}